<template>
  <material-chart-card
    :color="charts[0].color"
    :data="charts[0].data"
    :options="charts[0].options"
    :responsive-options="charts[0].responsiveOptions"
    :title="charts[0].title"
    :type="charts[0].type"
  >
    <template #subtitle>
      <div class="font-weight-light text--secondary">
        <div v-html="charts[0].subtitle" />
      </div>
    </template>

    <template #actions>
      <v-icon class="mr-1" small> mdi-clock-outline </v-icon>

      <span
        class="text-caption grey--text font-weight-light"
        v-text="charts[0].time"
      />
    </template>
  </material-chart-card>
</template>
<script>
import Chartist from 'chartist';
import chartist_tooltip from 'chartist-plugin-tooltip';

export default {
  mounted() {},
  name: 'GraficaDeBarras',
  components: {},
  data: () => ({
    charts: [
      {
        type: 'Bar',
        color: 'primary',
        title: 'Website Views',
        subtitle: 'Last Campaign Performance',
        time: 'updated 10 minutes ago',
        data: {
          labels: ['Al credito(Entregados)', 'Al contado(Entregados)'],
          series: [
            [
              { meta: 'credito pendientes', value: 575 }, //al credito entregado
              { meta: 'credito cobrado', value: 575 }, //al credito entregado
            ],
            [
              { meta: 'Contado pendiente', value: 478 }, //al credito pendiente
              { meta: 'Contado cobrado', value: 478 }, //al credito pendiente
            ],
          ],
        },
        options: {
          axisy: {
            showGrid: false,
          },
          plugins: [
            Chartist.plugins.tooltip(),
            chartist_tooltip({ anchorToPoint: true, appendToBody: true }),
          ],
          low: 443,
          high: 600,
          chartMargin: {
            top: 100,
          },
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 10,
          },
        },
        responsiveOptions: [
          [
            'screen and (max-width: 1540px)',
            {
              reverseData: false,
              horizontalBars: true,
              axisY: {
                offset: 76,
              },
              //la distancia "disponible" que hay entre barras
              seriesBarDistance: 15,
              axisy: {
                labelInterpolationFnc: function (value) {
                  return value.slice(0, 10);
                },
              },
            },
          ],
          [
            'screen and (max-width: 1040px)',
            {
              seriesBarDistance: 5,
              axisy: {
                labelInterpolationFnc: function (value) {
                  return value.slice(0, 10);
                },
              },
            },
          ],
        ],
      },
    ],
  }),
};
</script>
<style>
.ct-chart {
  position: relative;
}

.ct-tooltip {
  position: absolute;
  top: 15px;
  min-width: 5em;
  padding: 8px 10px;
  background-color: #383838;
  color: #fff;
  top: -50px;
  text-align: center;
  pointer-events: none;
  z-index: 9999999;
  transition: opacity 0.2s linear;
}

.ct-tooltip:before {
  position: absolute;
  z-index: 9999999;
  bottom: -14px;
  left: 50%;

  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(251, 249, 228, 0);
  border-top-color: #383838;
  border-width: 7px;
  margin-left: -8px;
}

.ct-tooltip.hide {
  display: block;
  opacity: 0;
  visibility: hidden;
}
.chartist-tooltip.tooltip-show {
  display: inline-block !important;
}
</style>
